export const phrases: string[] = [
  "One year's seeding makes seven years weeding",
  "Learn a language, and you will avoid a war",
  "Least said, soonest mended",
  "You have made your bed and you must lie in it",
  "Where there is life there is hope",
  "Keep your friends close and your enemies closer",
  "The bread never falls but on its buttered side",
  "Talk is cheap",
  "Half a loaf is better than no bread at all",
  "He who can, does; he who cannot, teaches",
  "Once bitten, twice shy",
  "Fall seven times, stand up eight",
  "Live for today, for tomorrow never comes",
  "In for a penny, in for a pound",
  "Moderation in all things",
  "It takes one to know one",
  "Never look a gift horse in the mouth",
  "Better to travel hopefully than to arrive",
  "If it were a snake, it would have bit you",
  "Waste not, want not",
  "The old wooden spoon beats me down",
  "Every man for himself and the Devil take the hindmost",
  "Handsome is as handsome does",
  "Whom the Gods love die young",
  "No accounting for bad taste",
  "No rest for the wicked",
  "Wonders will never cease",
  "Let the cat out of the bag",
  "All work and no play makes Jack a dull boy",
  "The truth will out",
  "If you cannot beat them, join them",
  "No time like the present",
  "The customer is always right",
  "Every cloud has a silver lining",
  "One good turn deserves another",
  "The shoemaker's son always goes barefoot",
  "The apple does not fall far from the tree",
  "Seeing is believing",
  "Kill the chicken to scare the monkey",
  "Don't cry over spilled milk",
  "The grass is always greener on the other side",
  "Better to light a candle than to curse the darkness",
  "Well done is better than well said",
  "From the sublime to the ridiculous is only a step",
  "Tomorrow is another day",
  "Easy come, easy go",
  "Better to reign in hell than serve in heaven",
  "Business before pleasure",
  "A rolling stone gathers no moss",
  "Never judge a book by its cover",
  "Don't wash your dirty linen in public",
  "Don't sympathize with those who can not empathize",
  "Good for the goose is good for the gander",
  "It is never too late",
  "Feed a cold, starve a fever",
  "Keep your chin up",
  "Other times other manners",
  "Never say die",
  "Any publicity is good publicity",
  "What goes around, comes around",
  "The stupid monkey knows not to eat the banana skin",
  "Finders keepers losers weepers",
  "Rules were made to be broken",
  "The more the merrier",
  "Turn your face toward the sun and the shadows fall behind you",
  "Marry in haste, repent at leisure",
  "East, west, home is best",
  "One man's trash is another man's treasure",
  "No news is good news",
  "Don't put the cart before the horse",
  "You cannot make bricks without straw",
  "Play stupid games, win stupid prizes",
  "Pride comes before a fall",
  "It ain't over till it's over",
  "Many hands make light work",
  "The apple never falls far from the tree",
  "You scratch my back and I will scratch yours",
  "One hand washes the other",
  "A man's home is his castle",
  "Cross the stream where it is shallowest",
  "Money makes the devil dance",
  "Don't judge a book by its cover",
  "Don't let the grass grow beneath your feet",
  "Imitation is the sincerest form of flattery",
  "At the end of my rope",
  "United we bargain; divided we beg",
  "Give the devil his due",
  "Don't cross the bridge till you come to it",
  "Idle hands are the devil's playthings",
  "In the midst of life, we are in death",
  "The age of miracles is past",
  "The streets are paved with gold",
  "Possession is nine-tenths of the law",
  "Hope springs eternal",
  "The good die young",
  "Don't cut off your nose to spite your face",
  "Beauty is only skin deep",
  "Cheese, wine, and friends must be old to be good",
  "You pay your money and you take your choice",
  "Whatever floats your boat",
  "Little things please little minds",
  "If you want a thing done well, do it yourself",
  "Knowledge is power, guard it well",
  "Enough is as good as a feast",
  "Tomorrow never comes",
  "Time and tide wait for no man",
  "Strike while the iron is hot",
  "You cannot push a rope",
  "Spare the rod and spoil the child",
  "An ounce of prevention is worth a pound of cure",
  "A little learning is a dangerous thing",
  "The pen is mightier than the sword",
  "Fortune favours the brave",
  "The proof is in the pudding",
  "Life is what you make of it",
  "Into every life a little rain must fall",
  "Revenge is a dish best served cold",
  "Cold hands, warm heart",
  "Give credit where credit is due",
  "All things come to those who wait",
  "Burn the candle at both ends",
  "No such thing as a free lunch",
  "He who hesitates is lost",
  "With great power comes great responsibility",
  "Eat, drink and be merry, for tomorrow we die",
  "They that sow the wind shall reap the whirlwind",
  "Beggars cannot be choosers",
  "Jack of all trades, master of one",
  "Money talks",
  "Life is not all rainbows and butterflies",
  "It is easy to be wise after the fall",
  "The best things in life aren't free",
  "It's all grist to the mill",
  "Don't let the bastards grind you down",
  "Once the poison, twice the charm",
  "Cream rises to the top",
  "A watched man never plays",
  "Better the Devil you know than the Devil you do not",
  "Put the cart before the horse",
  "One kind word can warm three winter months",
  "It is the last straw that breaks the camel's back",
  "Don't teach your Grandmother to suck eggs",
  "Life is short; do something that matters",
  "He who lives by the sword, dies by the sword",
  "A watched pot never boils",
  "You can't always get what you want",
  "A leopard cannot change its spots",
  "Where there's a will there's a way",
  "You cannot teach an old dog new tricks",
  "Like a snake in the grass",
  "If the eye does not see the heart does not crave",
  "Don't spend it all in one place",
  "To err is human, to forgive divine",
  "Do as I say, not as I do",
  "Two can play at that game",
  "Those who sleep with dogs will rise with fleas",
  "It is like juggling sand",
  "Don't make a mountain out of a mole hill",
  "If anything can go wrong, it will",
  "Clothes make the man",
  "If you've got it, flaunt it",
  "The enemy of my enemy is my friend",
  "When the cat is away, the mice will play",
  "Know which side your bread is buttered on",
  "All's well that ends well",
  "Hope for the best, and prepare for the worst",
  "Discretion is the better part of valour",
  "Well begun is half done",
  "Don't kill the goose that lays the golden eggs",
  "Many a true word is spoken in jest",
  "Kill two birds with one stone",
  "What cannot be cured must be endured",
  "Money earned by deceit, goes by deceit",
  "The spouse is always the last to know",
  "All the world loves a lover",
  "The way to someone's heart is through their stomach",
  "He who fights and runs away, may live to fight another day",
  "Every man has his price",
  "The road to Hell is paved with good intentions",
  "Revenge is sweet",
  "Give the devil her due",
  "Marriages are made in heaven",
  "The labourer is worthy of his hire",
  "You cannot unscramble eggs",
  "Don't put off until tomorrow what you can do today",
  "All things must pass",
  "Loose lips sink ships",
  "All is fair in love and war",
  "Children should be seen and not heard",
  "Don't throw the baby out with the bathwater",
  "Third time's the charm",
  "Shiny are the distant hills",
  "Nothing ventured, nothing gained",
  "Never say never",
  "Don't burn your bridges behind you",
  "No shame in not knowing, but in not finding out",
  "Procrastination is the thief of time",
  "No such thing as bad publicity",
  "Never let the sun go down on your anger",
  "A friend to everyone is a friend to no one",
  "Two wrongs do not a right make",
  "Never speak ill of the dead",
  "Hard work never did anyone any harm",
  "Let bygones be bygones",
  "The darkest hour is just before the dawn",
  "Too little, too late",
  "The last drop makes the cup run over",
  "Better to light a candle than curse the darkness",
  "While there is life there is hope",
  "Walnuts and pears you plant for your heirs",
  "United we stand, divided we fall",
  "Give a dog a bad name and hang him",
  "Familiarity breeds contempt",
  "Even from a foe a man may learn wisdom",
  "It's a small world",
  "Haste makes waste",
  "If you play with fire, you will get burned",
  "Practice what you preach",
  "The longest day must have an end",
  "Cowards may die many times before their death",
  "Absolute power corrupts absolutely",
  "Money is not everything",
  "Oil and water do not mix",
  "Another day, another dollar",
  "History repeats itself",
  "Boys will be boys",
  "You cannot run with the hare and hunt with the hounds",
  "Comparisons are odious",
  "Bad news travels fast",
  "Less is more",
  "Any port in a storm",
  "The course of true love never did run smooth",
  "Absolute power corrupts absolutely",
  "If a job is worth doing, it is worth doing well",
  "It goes without saying",
  "Never give a sucker an even break",
  "Uneasy lies the head that wears a crown",
  "Even a worm will turn",
  "Some are more equal than others",
  "Nothing succeeds like success",
  "No accounting for tastes",
  "A mill cannot grind with the water that is past",
  "Knock on wood",
  "Stupid is as stupid does",
  "A friend in need is a friend indeed",
  "Parsley seed goes nine times to the Devil",
  "Nature abhors a vacuum",
  "Misery loves company",
  "Coffee and love taste best when hot",
  "Love makes the world go around",
  "Count your blessings",
  "Use it or lose it",
  "No friends but the mountains",
  "All you need is love",
  "When you've seen one, you've seen them all",
  "A late lunch makes the day go faster",
  "As a tree bends, so shall it grow",
  "To the victor go the spoils",
  "More ways than one to skin a cat",
  "There is an exception to every rule",
  "Blood is thicker than water",
  "Fine feathers make fine birds",
  "Don't dish it if you can't take it",
  "Necessity is the mother of invention",
  "Where there is muck there is brass",
  "The cobbler always wears the worst shoes",
  "Don't upset the apple-cart",
  "Ugly is as ugly does",
  "Ignorance is bliss",
  "It will be the same a hundred years hence",
  "Better late than never",
  "One swallow does not make a summer",
  "Laugh before breakfast, cry before supper",
  "Honour among thieves",
  "Time is money",
  "Money makes the world go around",
  "Jack of all trades, master of none",
  "Virtue is its own reward",
  "An eye for an eye makes the whole world blind",
  "Good things come to those who wait",
  "Let the punishment fit the crime",
  "The best condiments are authentic flavors",
  "The comeback is greater than the setback",
  "More haste, less speed",
  "No smoke without fire",
  "Like trying to grow a goose",
  "Only time will tell",
  "First come, first served",
  "Speak of the devil and he shall appear",
  "Ask a silly question and you will get a silly answer",
  "The walls have eyes",
  "No use locking the stable door after the horse has bolted",
  "A chain is only as strong as its weakest link",
  "Birds of a feather flock together",
  "Separate the wheat from the chaff",
  "Ask no questions and hear no lies",
  "Speak softly and carry a big stick",
  "The more things change, the more they stay the same",
  "All is grist that comes to the mill",
  "Don't count your chickens before they've hatched",
  "To be worn out is to be renewed",
  "Cheaters never prosper",
  "One man's meat is another man's poison",
  "Every little bit helps",
  "Needs must when the devil drives",
  "False friends are worse than open enemies",
  "As you sow so shall you reap",
  "What goes up must come down",
  "Fake it till you make it",
  "Great minds think alike",
  "Time flies when you're having fun",
  "Hindsight is always twenty-twenty",
  "Man proposes, heaven disposes",
  "Everyone has their price",
  "Cut your coat according to your cloth",
  "Let sleeping dogs lie",
  "Big fish eat little fish",
  "A dog is a man's best friend",
  "The best-laid schemes of mice and men often go awry",
  "The hand that rocks the cradle rules the world",
  "You will never get if you never go",
  "Truth is stranger than fiction",
  "Better to have loved and lost",
  "To each his own",
  "The rich get richer and the poor get poorer",
  "Youth is wasted on the young",
  "Don't put too many irons in the fire",
  "Fair exchange is no robbery",
  "All hands on deck",
  "Doubt is the beginning, not the end, of wisdom",
  "Never let the truth get in the way of a good story",
  "Put your money where your mouth is",
  "You cannot make a silk purse from a sow's ear",
  "Out of sight, out of mind",
  "Variety is the spice of life",
  "Live and let live",
  "If the shoe fits, wear it",
  "Patience is a virtue",
  "Easier said than done",
  "The left hand knows not what the right hand is doing",
  "Only fools and horses work",
  "Hard cases make bad law",
  "When life gives you lemons, make lemonade",
  "Why keep a dog and bark yourself?",
  "A watched kettle never boils",
  "He who sups with the Devil should have a long spoon",
  "Mud sticks",
  "Sleep with the dogs and rise with fleas",
  "Let your hair down",
  "Give him an inch and he will take a mile",
  "A penny saved is a penny earned",
  "A bad workman blames his tools",
  "Every stick has two ends",
  "Speak as you find",
  "Music has charms to soothe the savage beast",
  "You must have rocks in your head",
  "The longest journey starts with a single step",
  "Practice makes perfect",
  "Laughter is the best medicine",
  "Money does not grow on trees",
  "There is no place like home",
  "The empty can makes the most noise",
  "When it rains it pours",
  "Kill the goose that lays the golden eggs",
  "Fight fire with fire",
  "A fool and his money are soon parted",
  "What does not kill me makes me stronger",
  "Let not the sun go down on your wrath",
  "Dead men tell no tales",
  "Don't change horses in midstream",
  "No fool like an old fool",
  "To travel hopefully is a better thing than to arrive",
  "An army marches on its stomach",
  "Open confession is good for the soul",
  "Physician, heal thyself",
  "Clothes don't make the man",
  "Don't look a gift horse in the mouth",
  "The squeaky wheel gets the grease",
  "Lightning never strikes the same place twice",
  "You catch more flies with honey than with vinegar",
  "Where there is smoke there is fire",
  "Sit crooked and talk straight",
  "Every dog has his day",
  "Let well enough alone",
  "See no evil, hear no evil, speak no evil",
  "An apple a day keeps the doctor away",
  "You cannot get blood out of a stone",
  "Right or wrong, my country",
  "No pain, no gain",
  "Worrying never did anyone any good",
  "Don't try to walk before you can crawl",
  "There's no such thing as a free lunch",
  "Easy, times easy, is still easy",
  "Don't keep a dog and bark yourself",
  "Brevity is the soul of wit",
  "Too much of a good thing",
  "The bigger they are, the harder they fall",
  "The early bird catches the worm",
  "The innocent seldom find an uncomfortable pillow",
  "Don't bite the hand that feeds you",
  "The Moon is made of green cheese",
  "Don't shut the stable door after the horse has bolted",
  "Love of money is the root of all evil",
  "Put your best foot forward",
  "Absence makes the heart grow fonder",
  "The pot is calling the kettle black",
  "Don't put all your eggs in one basket",
  "If it were not for hope the heart would break",
  "When the going gets tough, the tough get going",
  "Not all those who wander are lost",
  "Make hay while the sun shines",
  "Curiosity killed the cat",
  "Love will find a way",
  "Two is company, but three is a crowd",
  "Speech is silver but Silence is golden",
  "Failing to plan is planning to fail",
  "Like father, like son",
  "If you lie down with dogs, you will get up with fleas",
  "Look before you leap",
  "Talk of Angels, and hear the flutter of wings",
  "He who pays the piper calls the tune",
  "Many a slip 'twixt cup and lip",
  "Fish always rots from the head downwards",
  "What is learned in the cradle lasts to the tomb",
  "Don't meet troubles half-way",
  "Too many cooks spoil the broth",
  "Pearls of wisdom",
  "Preaching to the choir",
  "Accidents will happen",
  "There is one born every minute",
  "One law for the rich and another for the poor",
  "Unity is strength",
  "Safety in numbers",
  "Crime does not pay",
  "You are never fully dressed without a smile",
  "You cannot have your cake and eat it too",
  "Many a good tune played on an old fiddle",
  "A bird in the hand is worth two in the bush",
  "Time is the greatest healer",
  "Die a hero or live to see yourself the villain",
  "Actions speak louder than words",
  "He who laughs last laughs longest",
  "Manners maketh man",
  "A stitch in time saves nine",
  "Never give advice unless asked",
  "The walls have ears",
  "Walk softly but carry a big stick",
  "Flattery will get you nowhere",
  "Devil take the hindmost",
  "It will come back and haunt you",
  "Two wrongs do not make a right",
  "The best defense is a good offense",
  "The die is cast",
  "Up a creek without a paddle",
  "Set a thief to catch a thief",
  "Fools rush in where angels fear to tread",
  "An eye for an eye, a tooth for a tooth",
  "Fish and guests smell after three days",
  "Beauty is in the eye of the beholder",
  "A rising tide lifts all boats",
  "April showers bring May flowers",
  "The Devil looks after his own",
  "If at first you don't succeed, try, try again",
  "Seek and ye shall find",
  "Woe to the vanquished",
  "Adversity makes strange bedfellows",
  "You are what you eat",
  "Make love not war",
  "If it ain't broke, don't fix it",
  "If wishes were horses, beggars would ride",
  "You cannot make an omelette without breaking eggs",
  "There are always more fish in the sea",
  "The Devil finds work for idle hands to do",
  "Too much of a good thing",
  "Two birds with one stone",
  "Empty vessels make the most noise",
  "Good talk saves the food",
  "Memory is the treasure of the mind",
  "Good fences make good neighbors",
  "The only way to find a friend is to be one",
  "You are never too old to learn",
  "Fortune favours the bold",
  "Better to give than to receive",
  "Don't rock the boat",
  "If you cannot be good, be careful",
  "Zeal without knowledge is fire without light",
  "Barking dogs seldom bite",
  "Milking the bull",
  "Who will bell the cat?",
  "All good things must come to an end",
  "Die a hero or live long enough to become the villain",
  "No man is an island",
  "No guts, no glory",
  "A picture is worth a thousand words",
  "There are two sides to every question",
  "Tell the truth and shame the Devil",
  "The end justifies the means",
  "If you pay peanuts, you get monkeys",
  "Little strokes fell great oaks",
  "Out of the frying pan and into the fire",
  "Better safe than sorry",
  "The exception which proves the rule",
  "Shrouds have no pockets",
  "Genius is an infinite capacity for taking pains",
  "Respect is not given, it is earned",
  "The best things in life are free",
  "Home is where the heart is",
  "Nothing is certain but death and taxes",
  "It ain't over till the fat lady sings",
  "Still waters run deep",
  "It takes a thief to catch a thief",
  "Men are blind in their own cause",
  "Sauce for the goose is sauce for the gander",
  "First impressions are the most lasting",
  "God helps those who help themselves",
  "Time is a great healer",
  "A bad excuse is better than none",
  "Careless talk costs lives",
  "Honesty is the best policy",
  "Forewarned is forearmed",
  "Opportunity never knocks twice at any man's door",
  "Christmas comes but once a year",
  "No names, no pack-drill",
  "Mighty oaks from little acorns grow",
  "Two heads are better than one",
  "All that glitters is not gold",
  "Slow and steady wins the race",
  "A penny wise and a pound foolish",
  "You can't win them all",
  "There are more ways to kill a cat than choking it with cream",
  "The work praises the man",
  "All good things come to him who waits",
  "The proof of the pudding is in the eating",
  "Give a man rope enough and he will hang himself",
  "It takes a village to raise a child",
  "Born with a silver spoon",
  "Let the buyer beware",
  "The early bird that gets the worm",
]
